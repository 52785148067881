@font-face {
  font-family: 'PoiretOne-Regular';
  src: URL('./PoiretOne-Regular.ttf') format('truetype');
}

*, *::after, *::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  font-family: 'PoiretOne-Regular', serif;
}

#root {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  
}

.app {
  flex-grow: 1;
  display: flex;
}

.background {
  flex-basis: 0%;
  flex-shrink: 0;
  background-image: url('../images/background.webp');
  background-position: center;
  background-size: cover;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-basis: 100%;
  flex-shrink: 0;
  padding: 30px;
}

.img {
  display: block;
  object-fit: cover;
  object-position: center;
  width: 100%;
  aspect-ratio: 1/1;
}

.img--natural {
  aspect-ratio: inherit;
}

.img--radius {
  border-radius: 10px;
}

.socials {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  width: 100%;
}

.socials__link {
  width: 50px;
  height: 50px;
}

.logo {
  width: 330px;
}

.title {
  margin-bottom: 50px;
  text-align: center;
  text-transform: uppercase;
  font-size: 40px;
  font-weight: normal;
}

.catalog {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.subtitle {
  margin-bottom: 30px;
  font-size: 30px;
  font-weight: normal;
}

.catalog__link {
  width: 200px;
}

@media (min-width: 900px) {
  .background {
    flex-basis: 55%;
  }
  
  .content {
    flex-basis: 45%;
  }
}